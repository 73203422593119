@use "../config" as *;
.waf-player {
  padding-block: 0;
  margin-inline: var(--space-2-neg);
  margin-bottom: 0;
  @extend %bg-neutral-5;
  > .layout-wrapper {
    max-width: unset;
    > .waf-head {
      padding-block: 12rem 16.5rem;
      border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
      background: url(/static-assets/images/cssimages/pattern/details-bg-mob.png?v=#{$image-version}) center / cover no-repeat;
      isolation: isolate;
      @extend %relative;
      &::before {
        content: "";
        height: 60%;
        background: linear-gradient(0deg, hsl(var(--hsl-neutral-100) / 0) 0%, var(--neutral-100) 80%);
        @include position-combo("tl");
        @extend %w-100;
        @extend %zindex-pattern;
      }
      &::after {
        content: "";
        height: 77%;
        border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
        background: linear-gradient(180deg, hsl(var(--hsl-neutral-100) / 0) -11.48%, var(--neutral-100) 86.48%);
        @include position-combo("bl");
        @extend %w-100;
        @extend %zindex-pattern;
      }
      .title {
        letter-spacing: -.064rem;
        @include default-wrapper();
        @extend %neutral-0;
        @extend %title-30;
      }
    }
    > .waf-body {
      margin-top: -6.5rem;
      @include large-wrapper();
      @extend %relative;
    }
  }
  .banner-img {
    object-fit: cover;
    object-position: center;
    @extend %position-t-l;
    @extend %w-100;
    @extend %h-100;
    @extend %zindex-banner-bg;
  }
  .desc {
    @include default-wrapper();
    @extend %neutral-5;
    @extend %body-m;
  }
  .player {
    &-profile-wrapper {
      @extend %flex-column;
      @extend %gap-6;
      > * {
        @extend %p-6-4;
        @extend %border-radius-m;
        @extend %bg-neutral-0;
      }
      &.men {
        .player-thumbnail {
          border-color: var(--primary-50);
        }
      }
      &.women {
        .player-thumbnail {
          border-color: var(--secondary-70);
        }
      }
    }
    &-thumbnail {
      width: 12.8rem;
      height: 12.8rem;
      aspect-ratio: 1/1;
      border: 0.5rem solid;
      @extend %circle-radius;
      @extend %mb-5;
      img {
        object-fit: cover;
        @extend %w-100;
        @extend %h-100;
        @extend %circle-radius;
      }
    }
    &-origin {
      @extend %flex-n-c;
      @extend %gap-2;
      img {
        width: 2.4rem;
        aspect-ratio: 1/1;
        height: 2.4rem;
        @extend %circle-radius;
      }
      .country-flag {
        @extend %flex-n-c;
        @extend %gap-2;
      }
      .country-flag,
      .date {
        @extend %text-l;
        @extend %neutral-70;
      }
    }
    &-basic-info {
      @include grid(2);
      @extend %mb-6;
    }
    &-data {
      grid-area: 3/ span 2;
      @extend %flex-column;
      .label {
        @extend %body-lb;
        @extend %neutral-100;
      }
      .value {
        @extend %body-l;
        @extend %neutral-100;
      }
    }
    &-social {
      grid-area: 1/2;
      height: fit-content;
      @extend %flex-fe-n;
      @extend %gap-4;
    }
    &-ranking-info {
      border-top: 0.1rem solid var(--neutral-20);
      @extend %py-6;
      @extend %flex-column;
      @extend %gap-4;
      .title {
        @extend %title-50;
      }
    }
    &-rank-item {
      @extend %flex-sb-c;
      @extend %primary-40;
      &:hover {
        @extend %primary-70;
      }
    }
    &-rank-title {
      @extend %body-l;
    }
    &-rank-count {
      @extend %title-50;
    }
    &-history-info {
      @include grid(3, var(--space-10));
      @extend %bg-neutral-5;
      @extend %p-6-4;
      @extend %border-radius-s;
    }
    &-history-item {
      flex-direction: column-reverse;
      @extend %flex-c-c;
    }
    &-history-count {
      @extend %title-30;
      @extend %neutral-100;
    }
    &-history-title {
      @extend %text-l;
      @extend %neutral-70;
      @extend %text-center;
    }
    &-eventinfo-wrapper {
      order: 3;
      // .card-title {
      //   a {
      //     &:hover {
      //       text-decoration: underline;
      //       @extend %primary-50;
      //     }
      //   }
      // }
    }
    &-client-wrapper {
      order: 4;
      @extend %mb-8;
    }
    &-client-item {
      width: 9rem;
      aspect-ratio: 16/9;
      img {
        @extend %h-100;
      }
    }
    &-client-list {
      @extend %flex-sb-c;
      @extend %gap-14;
      @extend %px-6;
    }
    &-bio-wrapper {
      @extend %mb-20;
      .title {
        @extend %mb-6;
      }
      .text {
        @extend %mb-4;
        p {
          @extend %body-l;
        }
      }
    }
    &-detail-wrapper {
      &:not(:has(.player-bio-wrapper)) {
        @extend %mb-10;
        .player-result-wrapper {
          .head {
            align-items: flex-start;
            flex-direction: column-reverse;
            .title {
              @extend %mt-7;
              @extend %w-100;
            }
            .btn-link {
              flex-direction: row-reverse;
              padding-block: 0;
              min-height: unset;
              &::after {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }
    &-result-wrapper,
    &-news-wrapper,
    &-videos-wrapper {
      @extend %mb-20;
      .head {
        @extend %flex-sb-c;
        @extend %mb-6;
        .title {
          width: calc(100% - 10.3rem);
          @extend %mb-0;
        }
        .btn-link {
          @extend %px-0;
          @extend %py-3;
          @extend %btn-default;
          @extend %gap-2;
          @extend %flex;
          &:after {
            @include icon(arrow-right, 19);
          }
        }
      }
    }
    &-shop-list {
      @include card-count(1.1, var(--space-4));
      @extend %my-10;
      @extend %mx-4-neg;
      @extend %px-4;
    }
    &-shop-content {
      height: calc(100% - 23.4rem);
      @extend %p-4;
      @extend %flex-column-fe-n;
      .title {
        flex-grow: 1;
        @extend %body-mb;
        @extend %mb-0;
      }
    }
    &-shop-thumbnail {
      aspect-ratio: 3/4;
      max-height: 23.4rem;
      @extend %w-100;
      img {
        border-radius: var(--space-4) var(--space-4) 0 0;
        object-fit: cover;
        @extend %w-100;
        @extend %h-100;
      }
    }
    &-shop-item {
      @include border(1, neutral-20);
      @extend %border-radius-m;
      @extend %relative;
      .btn-link {
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
      }
      .btn-text {
        @extend %font-0;
      }
    }
    &-shop-value {
      @extend %title-50;
      @extend %mb-2;
    }
    &-shop-more {
      .btn-outline {
        @extend %btn-outline;
      }
    }
    &-news-wrapper {
      @include article-listing(horizontal);
      // @extend %py-20;
      .img-box {
        aspect-ratio: 1/1;
      }
      .article-list {
        display: grid;
      }
    }
    &-videos-wrapper {
      @include article-listing(overlay);
      .article {
        &-list {
          @extend %flex;
          @extend %x-auto;
        }
        &-content {
          .item-type-icon {
            display: none;
          }
        }
        &-title {
          @extend %title-40;
        }
      }
      .img-box {
        aspect-ratio: 3/4;
      }
    }
    &-photos-wrapper {
      @extend %py-0;
      @extend %mb-20;
      .head {
        .title {
          @extend %mb-0;
        }
      }
      .modal-window .btn-close::after {
        font-size: 3rem;
      }
      .body {
        aspect-ratio: 311 / 370;
      }
      .photos-swiper {
        height: 100%;
      }
      .swiper-slide {
        height: auto;
      }
    }
  }
  @each $social-icon, $social-value in $social {
    .icon-#{$social-icon} {
      &::before {
        content: unset;
      }
      @extend %flex;
      a {
        width: 2.4rem;
        height: 2.4rem;
        @extend %flex;
        &::before {
          @include icon(#{$social-icon}, 24);
          @extend %neutral-100;
          @extend %flex-c-c;
          @extend %w-100;
          @extend %h-100;
        }
      }
      .text {
        @extend %font-0;
      }
      &:hover {
        a::before {
          @extend %primary-55;
        }
      }
      &:focus {
        a::before {
          @extend %primary-85;
        }
      }
      &:disabled {
        a::before {
          @extend %neutral-40;
        }
      }
    }
  }
  .icon-twitter {
    a::before {
      font-size: 2.1rem;
    }
  }
  .icon-facebook {
    a::before {
      font-size: 2.7rem;
    }
  }
  @extend %event-item;
  .table {
    @extend %x-auto;
    .pos {
      flex-basis: 7rem;
    }
    .time {
      flex-basis: 10rem;
    }
    .date,
    .program {
      flex-basis: 12rem;
    }
    .event {
      flex-basis: 25rem;
    }
    .country {
      flex-basis: 8.8rem;
      justify-content: center;
      img {
        width: 3.5rem;
      }
    }
    &-row,
    &-data {
      justify-content: flex-start;
    }
    &-data {
      border-bottom: 0.1rem solid var(--neutral-20);
      @extend %px-4;
    }
    .text {
      @extend %text-left;
    }
    &-head {
      .table-row {
        @include border(1, neutral-20, 10, top);
      }
      .table-data {
        height: 6.2rem;
        @extend %bg-neutral-5;
        @extend %capitalize;
        .text {
          @extend %neutral-70;
          @extend %text-mb;
        }
      }
    }
    &-responsive {
      .loadmore-wrap {
        height: 8rem;
        @extend %flex-c-c;
        @include border(1, neutral-20, 10, bottom);
      }
      .btn-loadmore {
        font-weight: 700;
        @extend %primary-50;
        @extend %capitalize;
      }
    }
    &-body {
      .event {
        span {
          @extend %primary-50;
          &:hover {
            @extend %primary-55;
          }
        }
        a {
          @extend %text-left;
          @extend %flex;
        }
      }
    }
  }
  @extend %tabs-underline;
  .photos-swiper {
    position: relative;
    padding-bottom: 5rem;
    .article {
      &-list {
        display: flex;
      }
    }
    .swiper-pagination {
      right: unset;
      @extend %flex-c-c;
      @include position-combo(x-center, unset, 2rem);
      &-bullet {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: hsl(var(--hsl-neutral-100) / .7);
        flex-shrink: 0;
        &-active {
          background-color: var(--primary-50);
        }
      }
    }
  }
}
@include mq(col-tablet) {
  .waf-player {
    > .layout-wrapper {
      > .waf-head {
        background: url(/static-assets/images/cssimages/pattern/search-results-bg-d.png?v=#{$image-version}) center / cover no-repeat;
        .title {
          font-size: 5.6rem;
        }
      }
    }
    .player {
      &-videos-wrapper {
        .article {
          &-list {
            @include grid(3, var(--space-6));
          }
        }
      }
      &-shop-list {
        @include card-count(2.3, var(--space-4));
      }
      &-photos-wrapper {
        .body {
          aspect-ratio: 749 / 622;
        }
        .article-item:hover {
          .btn-more {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 76.04%),
              linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
            &::before {
              @include flex-config(flex, row, center, center);
            }
          }
        }
        .btn-more {
          &::before {
            display: none;
            width: 6.4rem;
            height: 6.4rem;
            background-color: hsl(var(--hsl-neutral-0)/0.2);
            color: var(--neutral-0);
            border-radius: 0.8rem;
            @include icon(magnifying-glass-plus, 26);
          }
        }
      }
      &-detail-wrapper {
        &:not(:has(.player-bio-wrapper)) {
          .player-result-wrapper {
            .head {
              align-items: flex-end;
              flex-direction: inherit;
              .title {
                width: auto;
              }
              .btn-link {
                flex-direction: inherit;
                &::after {
                  rotate: inherit;
                }
              }
            }
          }
        }
      }
      &-result-wrapper {
        .head {
          .title {
            width: calc(100% - 17.3rem);
          }
        }
      }
    }
    .table {
      &-data {
        flex: 1;
        border: unset;
      }
      &-row {
        padding-block: var(--space-4);
        border-bottom: 0.1rem solid var(--neutral-20);
      }
      &-head {
        .table-row {
          padding-bottom: 0;
        }
      }
      .time {
        flex-basis: 8rem;
      }
    }
    .photos-swiper {
      padding-bottom: 0;
      .swiper-pagination {
        right: 13.4rem;
        top: 5.5rem;
        width: max-content;
        bottom: unset;
        left: unset;
        font-size: 1.6rem;
      }
    }
  }
}
@include mq(col-lg) {
  .waf-player {
    .layout-wrapper {
      .waf-head {
        padding-block: 19rem;
      }
    }
    .player {
      &-profile-wrapper {
        display: grid;
        grid-template-columns: 45rem calc(100% - 47.4rem);
      }
      &-info-wrapper {
        height: max-content;
        grid-area: 1 / 1 / 2 / 2;
      }
      &-info-wrapper,
      &-eventinfo-wrapper {
        padding: 2.4rem;
      }
      &-client-wrapper {
        grid-area: 3 / 1 / 4 / 2;
      }
      &-eventinfo-wrapper {
        grid-area: 2 / 1 / 3 / 2;
      }
      &-detail-wrapper {
        grid-area: 1 / 2 / 6 / 3;
        margin-bottom: 2.4rem;
        padding: 4.8rem;
      }
      &-bio-wrapper {
        display: grid;
        grid-template-columns: 15rem calc(100% - 15rem);
        .text {
          grid-column: 2/3;
          grid-row: auto;
        }
      }
      &-shop-list {
        @include grid(3, var(--space-6));
      }
      &-shop-item {
        width: auto;
      }
      &-shop-more {
        @include flex-config(flex, null, center, center);
        .btn-outline {
          width: max-content;
        }
      }
      // &-photos-wrapper {
      //   padding-block: 8rem;
      // }
      &-news-wrapper {
        .article {
          &-thumbnail {
            width: 30.2rem;
          }
        }
        .img-box {
          aspect-ratio: 16/9;
        }
      }
    }
    .table {
      width: max-content;
    }
  }
}
@include mq(col-desktop) {
  .waf-player {
    .table {
      width: 100%;
      .event {
        flex-basis: 28rem;
      }
    }
  }
}
@include mq(col-xl) {
  .waf-player {
    .table {
      .event {
        flex-basis: 38rem;
      }
    }
  }
}